import styles from '@styles/components/AdvanceSearch/AdvanceDropdown.module.scss';
import Highlighter from '@components/highlighter';
import { nonAccentVietnamese } from '@utils/helper';
import React from 'react';
import { routeSearchList } from '@utils/configs/SearchList/const';

type Props = {
  searchValue?: string,
  showString?: string,
  fullText?: string,
  matchedItems?: string,
  realEstateType?: string,
  province?: string,
  district?: string,
  ward?: string,
  street?: string,
  typeOfDemand?: string,
};
const queryOnRoute = ({ province, district, ward, street }) => {
  let query = '';
  if (province?.length) {
    query = `?province=${province}`;
  }
  if (district?.length) {
    query = query + `&district=${district}`;
  }
  if (ward?.length) {
    query = query + `&ward=${ward}`;
  }
  if (street?.length) {
    query = query + `&street=${street}`;
  }
  return query;
};

export default function ItemSearchResult(props: Props) {
  const {
    searchValue,
    fullText,
    realEstateType,
    province,
    district,
    ward,
    street,
    typeOfDemand,
  } = props;
  const query = queryOnRoute({ province, district, street, ward });
  const link = `/${routeSearchList}/${typeOfDemand}/${realEstateType}/allPostType${query}`;
  return (
    <a style={{ minHeight: 35 }} href={link} className={styles.itemSearch}>
      <img
        style={{ minWidth: 16, minHeight: 16 }}
        src={`/svg/home/${realEstateType}.svg`}
        alt="icon"
      />
      <Highlighter
        sanitize={nonAccentVietnamese}
        style="py-3 flex-1"
        highlightStyle="text-blue-main font-bold truncate"
        searchWords={
          searchValue?.toString()?.length > 0
            ? searchValue?.toString().split(' ')
            : []
        }
        textToHighlight={fullText}
      />
    </a>
  );
}
