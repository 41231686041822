import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import MyInputV2 from '@components/inputV2';
import styles from '@styles/components/Home/Banner.module.scss';
import dynamic from 'next/dynamic';
import TabBar from '@components/TabBar';
import { options } from '@components/AdvanceSearch/configs';
import useDebounce from 'react-use/lib/useDebounce';
import { useQuery } from '@apollo/client';
import { GET_SUGGESTIONS } from '@schemas/queries/map';
import ItemSearchResult from '@components/AdvanceSearch/components/ItemSearchResult';
import { useRouter } from 'next/router';
import { pickBy } from 'lodash';
import { routeSearchList } from '@utils/configs/SearchList/const';
import { allPostType } from '@utils/configs/SearchList/SearchListConfigs';

const MagnifyGlass = dynamic(() => import('@public/svg/magnifyingGlass.svg'));

type Props = {
  close?: Function,
};

export default function AdvanceSearchMobile(props: Props) {
  const { close } = props;
  const { watch, setValue } = useFormContext(),
    [search, setSearch] = useState(''),
    [debounce, setDebounce] = useState(search || '');

  const isForSell = watch('isForSell');

  useDebounce(
    () => {
      setDebounce(search);
    },
    400,
    [search],
  );

  const { data } = useQuery(GET_SUGGESTIONS, {
    variables: {
      search: debounce,
      typeOfDemand: isForSell.replace('Project', 'ForSale'),
    },
    skip: !debounce?.length,
  });

  return (
    <div className="bg-white flex flex-col" style={{ minHeight: '100vh' }}>
      <div
        className="sticky flex top-0 justify-center w-full py-2 bg-white"
        style={{ zIndex: 1500 }}>
        <span className="text-16 font-text text-blue-main font-bold">
          Tìm kiếm bất động sản
        </span>
        <img
          onClick={close}
          alt="close"
          src="/svg/closeIcon.svg"
          className="w-26px h-26px absolute right-2"
        />
      </div>
      <div className="flex flex-col px-16px flex-1">
        <MyInputV2
          onDelete={() => setSearch('')}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder="Nhập từ khóa để tìm kiếm"
          inputClass="h-40px"
          inputClassName="w-full"
          inputOuterClassName={styles.lineSearch}
          renderRightComponent={() => {
            return <SearchButton search={search} />;
          }}
        />
        <div
          className="overflow-x-auto"
          style={{ marginTop: 30, zIndex: 1000 }}>
          <TabBar
            tabs={options}
            activeTab={isForSell}
            setActiveTab={(nextValue) => setValue('isForSell', nextValue)}
          />
        </div>
        <div className="flex flex-col flex-1">
          {data?.getSuggestions?.map((item, index) => {
            return (
              <ItemSearchResult
                {...item}
                typeOfDemand={isForSell}
                searchValue={search}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

type PropsSearch = {
  search?: String,
};

function SearchButton({ search }: PropsSearch) {
  const router = useRouter();
  const searchString = search;
  const variables = pickBy(
    {
      searchString,
    },
    (e) => e,
  );
  const pathname = `/${routeSearchList}/${
    searchString.includes('TRB') ? 'ForSale' : 'ForRent'
  }/allRealEstateType/${allPostType}`;
  const commonPathname = pathname;
  return (
    <div
      className="flex items-center pr-6px"
      onClick={async () => {
        await router.push({
          pathname: commonPathname,
          query: variables,
        });
      }}>
      <MagnifyGlass className="w-22px h-22px" fill="#B6B6B6" />
    </div>
  );
}
