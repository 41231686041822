import React from 'react';
import { findAll } from 'highlight-words-core';

type HighlighterProps = {
  autoEscape?: any,
  highlightStyle?: string,
  searchWords?: Array,
  textToHighlight?: string,
  sanitize?: any,
  onPressNormalText?: Function,
  style?: string,
};

export default function Highlighter({
  autoEscape,
  highlightStyle,
  searchWords,
  textToHighlight,
  sanitize,
  onPressNormalText,
  style,
  ...props
}: HighlighterProps) {
  const chunks = findAll({
    textToHighlight,
    searchWords,
    sanitize,
    autoEscape,
  });
  return (
    <span>
      {chunks?.map((chunk, index) => {
        const text = textToHighlight
          ?.toString()
          ?.substr(chunk.start, chunk.end - chunk.start);
        return (
          <span
            {...props}
            onClick={onPressNormalText}
            key={index}
            className={`text-13 ${chunk.highlight ? highlightStyle : style}`}>
            {text}
          </span>
        );
      })}
    </span>
  );
}
