import dynamic from 'next/dynamic';
import DrawerWrapper from '@components/DrawerWrapper';
import { useForm, FormProvider } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import AdvanceSearchDrawer from '@components/AdvanceSearchDrawer';
import { defaultConfigs } from '@components/AdvanceSearch/configs';
import AdvanceSearchMobile from '@components/pages/Home/Components/advanceSearchMobile';
const MagnifyGlass = dynamic(() => import('@public/svg/magnifyingGlass.svg'));
const Filter = dynamic(() => import('@public/svg/filter_blue.svg'));

export default function Search() {
  const form = useForm({
    defaultValues: {
      ...defaultConfigs,
    },
  });
  return (
    <FormProvider {...form}>
      <div className="h-40px border border-blue-main rounded-rd8px bg-white w-full flex px-8px">
        <AdvanceSearch />
        <div className="flex flex-row items-center pr-6px">
          <div className="w-1px h-30px border-l border-gray-400 mx-8px" />
          <FilterButton />
        </div>
      </div>
    </FormProvider>
  );
}

function AdvanceSearch() {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    open
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [open]);
  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="flex-1 flex items-center justify-between"
        style={{ color: '#888888' }}>
        <span className="text-14 text-left font-text">
          Nhập từ khóa để tìm kiếm
        </span>
        <MagnifyGlass className="w-17px h-17px" fill="#B6B6B6" />
      </button>
      {open && (
        <DrawerWrapper
          open={open}
          onClose={() => setOpen(false)}
          className="w-full"
          customTopAnchorBottom="5%">
          <AdvanceSearchMobile close={() => setOpen(false)} />
        </DrawerWrapper>
      )}
    </>
  );
}

function FilterButton() {
  const [toggleDrawer, onToggleDrawer] = useState(false);
  useEffect(() => {
    toggleDrawer
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [toggleDrawer]);
  return (
    <>
      <div onClick={() => onToggleDrawer(!toggleDrawer)}>
        <Filter className="w-20px h-20px" />
      </div>
      {toggleDrawer && (
        <DrawerWrapper
          open={toggleDrawer}
          onClose={() => onToggleDrawer(false)}
          className="w-full"
          customTopAnchorBottom="5%">
          <AdvanceSearchDrawer onToggleDrawer={onToggleDrawer} />
        </DrawerWrapper>
      )}
    </>
  );
}
