import HorizontalBar from '@components/HorizontalBar';
import useMeasure from 'react-use/lib/useMeasure';
import styles from '@styles/components/TabBar.module.css';
import { useRouter } from 'next/router';

type Props = {
  tabs?: Array<{ value?: string, key?: string }>,
  activeTab?: string,
  setActiveTab?: Function,
  wrapperClass?: string,
  backToFirstPage?: Boolean,
};

export default function TabBar(props: Props) {
  const {
    tabs = [],
    activeTab,
    setActiveTab,
    wrapperClass,
    backToFirstPage,
  } = props;
  return (
    <div className={`${wrapperClass} ${styles.container}`}>
      <button className="flex flex-row space-x-4 flex-1 pb-2 border-b border-gray-300 relative">
        {tabs.map((item) => {
          const isActive = activeTab === item.key;
          return (
            <Tab
              key={item.key}
              item={item}
              isActive={isActive}
              setActiveTab={setActiveTab}
              backToFirstPage={backToFirstPage}
            />
          );
        })}
      </button>
    </div>
  );
}

type TabProps = {
  item?: Object,
  setActiveTab?: Function,
  isActive?: boolean,
  backToFirstPage?: Boolean,
};

function Tab(props: TabProps) {
  const { item, setActiveTab, isActive, backToFirstPage } = props;

  const [measureRef, { width }] = useMeasure();
  const router = useRouter();
  return (
    <div
      ref={measureRef}
      className={`flex flex-col`}
      onClick={() => {
        setActiveTab(item.key);
        if (backToFirstPage) {
          router.push(router.query);
        }
      }}>
      <p
        className={`${
          isActive && 'text-blue-main font-bold font-textBold'
        } font-text text-14 duration-300 whitespace-nowrap`}>
        {item.value}
      </p>
      <HorizontalBar
        open={isActive}
        width={width / 2}
        className="absolute -bottom-0.5 transform translate-x-1/2"
      />
    </div>
  );
}
